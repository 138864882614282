<template>

    <div class="div-table-rows-container">

        <div
           
            :class="[activeId === item.id  ? activeClass : '', (item.IsMain) && item.Status != 7 ? 'font-weight-bold' : '', 'div-table-row']"
            @click.stop="taskClick(item)"
            @dblclick.stop="taskDblClick(item)"
        >
            <!-- Ячейка Текст-->
            <div class="div-table-row-cell" :style="{ 'width': `calc(50% - ${margin}px)`, 'margin-left': `${margin}px` }" >
                
                <div class="elipsis-text-wrap">
                    <span class="last-with-line" v-if="!isParent && margin != 0"></span>
                    <!--Toggle-->
                    <span v-if="isParent || hasExecutions">
                        <i :class="[ isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right']" @click.stop="toggle" @dblclick.stop="() => {}"></i>
                    </span>
                    <span v-if="!isParent && !hasExecutions && margin == 0" class="resol-tree-no-icon-area"></span>

                    <template v-if="item.IsActItem">
                        <span>
                            {{ $t("Пункт") }} № {{ item.ActItemNumber }}
                        </span>
                    </template>
                    <template v-else>
                        <span
                            v-if="item.IsInnerTask"
                            :class="item.Status == 7 ? 'text-decoration-line-through' : ''"
                        >
                            {{ taskTypeString }}
                        </span>
                        <span
                            v-else
                            :class="item.Status == 7 ? 'text-decoration-line-through' : ''"
                        >
                            {{ $t("Внешняя_резолюция_ВР") }}
                        </span>
                    </template>

                    <template v-if="isReportQuery">
                        <template v-for="(svod, i) in sortExecuters(item.Executers).filter(x => x.IsMain)">
                            <v-workplace-chip
                                :key="i"
                                :id="svod.ExecuterWorkplaceId"
                                :name="svod.ExecuterName"
                                :iconColor="executerStatusColor(svod)"
                                :main="false"
                                :is-employee="true"
                            />
                        </template>
                    </template>
                    <template v-else>
                        <span :class="item.Status == 7 ? 'text-decoration-line-through' : ''">
                            {{ $moment(item.CreateDate).format('DD.MM.YYYY HH:mm:ss') }}
                        </span>

                        <v-employee-chip
                            v-if="item.IsInnerTask"
                            :id="item.AuthorEmployeeId"
                            :name="item.AuthorName"
                            :is-employee="true"
                        />
                        <v-enterprise-chip
                            v-else :name="item.AuthorName"
                        />

                        <i v-if="item.IsSignerControl === true" class="fas fa-glasses signer-control-i" v-tooltip.top-center="isSignerControlTooltip"></i>

                        <div class="etw-text-container" v-tooltip.top-center="item.Text">
                            <span :class="[ item.Status == 7 ? 'text-decoration-line-through' : '', 'etw-text']">
                                {{ item.Text }}
                            </span>
                        </div>
                    </template>
                    

                </div>

            </div>

            <!-- Ячейка Исполнитель-->
            <div class="div-table-row-cell" style="width: 30%;">

                <div class="more-per-lab-wrap">

                    <template v-if="isReportQuery">
                        <template v-for="(executer, i) in sortExecuters(item.Executers).filter(x => !x.isMain)" >

                            <v-enterprise-chip v-if="executer.ExecuterWorkplaceId === '00000000-0000-0000-0000-000000000000'"
                                :key="i"
                                :name="executer.ExecuterName"
                                :iconColor="executerStatusColor(executer)"
                                :main="executer.IsMain"
                            />

                            <v-workplace-chip v-else
                                :key="i"
                                :id="executer.ExecuterWorkplaceId"
                                :name="executer.ExecuterName"
                                :iconColor="executerStatusColor(executer)"
                                :main="executer.IsMain"
                                :is-employee="true"
                            />

                        </template>
                    </template>

                    <template v-else>
                        <template v-if="item.IsInnerTask">
                            <template v-for="(executer, i) in sortExecuters(item.Executers)" >                                                    
                                <v-enterprise-chip
                                    v-if="executer.ExecuterWorkplaceId === '00000000-0000-0000-0000-000000000000'"
                                    :key="i"
                                    :name="executer.ExecuterName"
                                    :iconColor="executerStatusColor(executer)"
                                    :main="executer.IsMain"
                                />
                                <v-workplace-chip
                                    v-else
                                    :key="i"
                                    :id="executer.ExecuterWorkplaceId"
                                    :name="executer.ExecuterName"
                                    :iconColor="executerStatusColor(executer)"
                                    :main="executer.IsMain"
                                    :is-employee="true"
                                />
                            </template>
                        </template>

                        <template v-else>
                            <template v-for="(executer, i) in sortExecuters(item.Executers)" >
                                <v-enterprise-chip
                                    v-if="executer.ExecuterWorkplaceId === '00000000-0000-0000-0000-000000000000'"
                                    :key="i"
                                    :name="executer.ExecuterName"
                                    :iconColor="executerStatusColor(executer)"
                                    :main="executer.IsMain"
                                />
                                <v-workplace-chip
                                    v-else
                                    :key="i"
                                    :id="executer.ExecuterWorkplaceId"
                                    :name="executer.ExecuterName"
                                    :iconColor="executerStatusColor(executer)"
                                    :main="executer.IsMain"
                                    :is-employee="true"
                                />
                            </template>
                        </template>
                    </template>                    

                </div>

            </div>

            <!-- Ячейка Контрольный срок-->
            <div class="div-table-row-cell" style="width: 130px;flex-shrink: 0;">
                <div class="elipsis-text-wrap">
                    <i v-if="item.IsPeriodic" class="fas fa-history"></i>
                    <span v-if="item.ControlDate" :class="item.Status == 7 ? 'text-decoration-line-through' : ''">
                        {{ $moment(item.ControlDate).format('DD.MM.YYYY') }}
                    </span>
                </div>
            </div>

            <!-- Ячейка Статус-->
            <div class="div-table-row-cell" style="width: 160px;flex-shrink: 0;">
                <div class="onlyReadData more-per-lab-wrap"> 
                    <StatusChip
                        v-if="item.IsInnerTask === false && item.Executions && item.Executions.length > 0"
                        :status="avaibleStatuses[$helpers.getTaskStatus(item.Status)]"
                    />

                    <template v-else-if="item.IsInnerTask === true">
                        <StatusChip
                            v-if="item.ControlDate && getDaysDiff(item.ControlDate) > -1 && item.Status === 5"
                            :status="avaibleStatuses[$helpers.getTaskStatus(1)]"
                        />
                        <StatusChip
                            v-else
                            :status="avaibleStatuses[$helpers.getTaskStatus(item.Status)]"
                        />

                    </template>
                    
                    <StatusChip v-if="item.CanSendStatus" :status="avaibleStatuses[$options.rssStatusKeys[item.CanSendStatus]]" />                    
                </div>
            </div>

            <!--Ячейка действий-->
            <div v-if="showInteractions && !docInArch" class="resol-tree-action-col">
                <v-popover
                    trigger="click"
                >
                    <v-chip
                        class="person-label"
                        small
                        label
                        color="orange lighten-3"
                        v-if="getTaskActions(item).length > 0"
                    >
                        <v-icon color="#494F59">fas fa-ellipsis-v</v-icon>
                    </v-chip>

                    <template slot="popover">
                        <ul class="ribbon-button-submenu">
                            <li
                                v-for="(action, index) in getTaskActions(item)"
                                :key="index"
                                @click="executeAction(action)"
                                v-close-popover
                            >
                                <v-icon left>
                                    {{ action.icon }}
                                </v-icon>
                               {{ action.text }}
                            </li>
                        </ul>
                        <span class="ribbon-button-submenu-title">{{ $t("Подменю_действий") }}</span>
                    </template>
                </v-popover>
            </div>

        </div>

        <!--Рекурсия компонента-->
        <template v-if="isParent">

            <task-item
                v-show="isOpen"
                v-for="child in sortByMain(item.Items)"
                :key="child.id"
                :knownExecuters="knownExecuters"
                :is-main="child.IsMain"
                :item="child"
                :level="level + 1"
                :open-all="openAll"
                :active-id="activeId"
                :active-class="activeClass"
                :task-click="taskClick"
                :task-dbl-click="taskDblClick"
                :execution-click="executionClick"
                :execution-dbl-click="executionDblClick"
            />

        </template>

        <template v-if="hasExecutions">

            <div
                v-for="execution in item.Executions"
                :key="execution.ExecutionId"
                v-show="isOpen"
                :class="[activeId === execution.ExecutionId  ? activeClass : '', calculateisMainExecution(item.Executers, execution, item) ? 'font-weight-bold' : '', 'div-table-row', 'dtr-full-width']"
                @click.stop="executionClick({ ...execution, isCommon: item.IsCommon })"
                @dblclick.stop="executionDblClick({ ...execution, isCommon: item.IsCommon })"
            >
                <div 
                class="div-table-row-cell"
                :style="{ 'margin-left': `${executionMargin}px`, 'width': `calc(100% - ${executionMargin}px)` }"
                >
                    <div class="elipsis-text-wrap">
                        <span class="last-with-line"></span>

                        <span>
                            {{ executionTypeString }}
                        </span>

                        <span v-if="execution.CreateDate">
                            {{ $moment(execution.CreateDate).format('DD.MM.YYYY HH:mm:ss') }}
                        </span>

                        <v-enterprise-chip
                            v-if="execution.ExecuterWorkplaceId === '00000000-0000-0000-0000-000000000000'"
                            :name="execution.ExecuterName"
                            :icon="''"
                        />
                        <v-workplace-chip
                            v-else
                            :id="execution.ExecuterWorkplaceId"
                            :name="execution.ExecuterName"
                            :is-employee="true"
                        />

                        <span v-if="execution.ReturnReason && execution.ReturnReason.length > 0">
                            {{ $t("Отклонено") }}: {{ execution.ReturnReason }}
                        </span>

                        <div v-if="!isReportQuery" class="etw-text-container">
                            <span class="etw-text" v-tooltip.top-center="execution.Text">
                                {{ execution.Text }}
                            </span>
                        </div>
                    </div>    
                </div>
            </div>

        </template>

    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import StatusChip from '@/components/global/chips/StatusChip';

export default {
    name: "task-item",
    rssStatusKeys:["", "rssOnSign", "rssSignedByChief", "rssSended"],
    props: {
        isMain: {
            type: Boolean,
            default: false
        },
        item: Object,
        level: {
            type: Number,
            default: 0
        },
        knownExecuters: Object,
        openAll: Boolean,
        activeId: String,
        activeClass: String,
        taskActions: Object,
        taskClick: Function,
        taskDblClick: Function,
        executionClick: Function,
        executionDblClick: Function,
    },
    components: {
        StatusChip
    },
    data() {
        return {
            isOpen: true
        }
    },
    computed: {
        ...mapGetters('global/actionsource', { sourceType: 'getDataSourceType', isDataSourceCommon: 'isDataSourceCommon' }),
        ...mapGetters('global/actionsource', { documentSource: 'getDataSource' }),
        ...mapGetters('global/auth', { isCommonServiceEnabled: 'isCommonServiceEnabled' }),
        showInteractions() {
            if (this.isDataSourceCommon && !this.isCommonServiceEnabled)
                return false;
                
            return true;
        },
        taskTypeString() {
            switch (this.sourceType)
            {
                case "IQalaStatement":
                    return this.$t("Наряд");

                case "ReportQuery":
                    return this.$t("Задача_к_своду");

                default:
                    return this.$t("Карточка_резолюции_КР");
            }
        },
        executionTypeString() {
            switch (this.sourceType)
            {
                case "ReportQuery":
                    return this.$t("Заполнение");

                default:
                    return this.$t("Карточка_исполнения_КИ");
            }
        },
        executerCreateNewResolutionText() {
            switch (this.sourceType)
            {
                case "IQalaStatement":
                    return this.$t("Добавить_наряд");
                    
                default:
                    return this.$t("Добавить_резолюцию");
            }
        },
        executerResendText() {
            switch (this.sourceType)
            {
                case "IQalaStatement":
                    return this.$t("Перепоручить_исполнение_наряда");
                
                case "ReportQuery":
                    return this.$t("Перепоручить_заполнение_отчета");

                default:
                    return this.$t("Перепоручить_исполнение_резолюции");
            }
        },
        executerGiveAnswerText() {
            switch (this.sourceType)
            {
                case "IQalaStatement":
                    return this.$t("Дать_ответ_на_наряд");

                case "ReportQuery":
                    return this.$t("Заполнить_отчет");

                default:
                    return this.$t("Дать_ответ_на_резолюцию");
            }
        },
        executerAcceptText() {
            return this.$t("Принято_к_сведению");
        },
        executerAcceptTooltip() {
            return this.$t("Принять_к_сведению_резолюцию");
        },
        cancelExecutionText() {
            switch (this.sourceType)
            {
                case "ReportQuery":
                    return this.$t("Отозвать_заполнение");

                default:
                    return this.$t("Отозвать_исполнение");
            }
        },
        cancelExecutionTooltip() {
            return this.$t("Отозвать_карточку_исполнения");
        },
        isParent() {
            return this.item.Items != null && this.item.Items.length > 0;
        },
        hasExecutions() {
            return this.item.Executions && this.item.Executions.length > 0;
        },
        isSignerControlTooltip () {
            return this.$t("Личный_контроль");
        },
        avaibleStatuses() {
            return this.$store.getters['documents/references/GetAvaibleStatuses'];
        },
        margin() {
            return (this.isParent || this.hasExecutions) && this.level > 0 ?  (this.level * 30) : this.level * 30;
        },
        executionMargin() {
            return (this.level + 1) * 30;
        },
        docInArch() {
            return this.documentSource?.Data?.Object?.Document?.ArchYear != null;
        },
        isReportQuery() {
            return this.sourceType == "ReportQuery";
        }
    },
    methods: {
        toggle() {
            if (this.isParent || this.hasExecutions)
                this.isOpen = !this.isOpen;
        },
        sortExecuters(rawExecutersArray) {
            var sorted = [...rawExecutersArray];
            return sorted.sort(function(x, y) {
                return (x.IsMain === y.IsMain) ? 0 : x.IsMain ? -1 : 1;
            });
        },
        executerStatusColor(item) {
            switch(item.ExecuterStatusText)
            {
                case 'success':
                    return "#43B581";
                case 'warning':
                    return "#FAA61A";
                case 'danger':
                    return "#F04747";
                default:
                    return null;
            }
        },
        getDaysDiff(controlDate) {
            let diff = this.$moment(controlDate).diff(this.$moment(Date.now()), 'days');
            return diff;
        },
        executeAction(item) {
            this.$store.dispatch(`global/actionsource/${item.action}`, { updateSource: false, actionData: { ...item } });
        },
        getTaskActions(task) {
            let taskActions = [];
                     
            let { id, Executers, MyExecuterId, /*canCreateResolution,*/ CanRedirect, CanAnswer, CanAccept, CanCancelExecution } = task;                
            let taskExecuter = Executers.find(ex => ex.id === MyExecuterId);

            if (taskExecuter) {
                if (CanRedirect)
                    taskActions.push(
                    { 
                        action: "ExecuterResend",
                        icon: "fas fa-directions",
                        text: this.executerResendText,
                        tooltip: this.executerResendText,
                        resolutionId: taskExecuter.ResolutionId,
                        taskId: id                            
                    });
                
                if (CanAnswer)
                    taskActions.push(
                    { 
                        action: "ExecuterGiveAnswer",
                        icon: "fas fa-check-circle",
                        text: this.executerGiveAnswerText,
                        tooltip: this.executerGiveAnswerText,
                        resolutionId: taskExecuter.ResolutionId,
                        taskId: id,
                        executerId: MyExecuterId,
                        isMainTask: task.IsMain,
                        executerWorkplaceId: taskExecuter.ExecuterWorkplaceId
                    });
                
                if (CanAccept)
                    taskActions.push(
                    { 
                        action: "AcceptResolution",
                        icon: "fas fa-check-circle",
                        text: this.executerAcceptText,
                        tooltip: this.executerAcceptTooltip,
                        resolutionId: taskExecuter.ResolutionId,
                        taskId: id
                    });

                if (CanCancelExecution)
                    taskActions.push(
                    { 
                        action: "CancelExecution",
                        icon: "fas fa-undo",
                        text: this.cancelExecutionText,
                        tooltip: this.cancelExecutionTooltip,
                        id: taskExecuter.ResolutionId,
                        taskId: id,
                        executerId: MyExecuterId
                    });
            }
            
            return taskActions;
        },
        calculateisMainExecution(executers, execution, parent) {
            if (!executers)
                return false;

            let executer = executers.find(x => x.ExecuterWorkplaceId == execution.ExecuterWorkplaceId);
            return executer && executer.IsMain && parent.IsMain;
        },
        sortByMain(items) {
            return this.$_.orderBy(items, ['isMain', 'createDate'], ['desc', 'asc']);
        }
    },
    watch: {
        openAll(newValue) {
            this.isOpen = newValue;
        }
    }
}
</script>