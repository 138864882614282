<template>
    <v-form v-if="dataSource && knownExecuters" class="wrapperForm">
        <v-row dense>
            <v-col cols="12" sm="12" md="12" class="section-wrapper">
                <v-card flat>
                    <v-card-text>

                        <div class="form-box-title title-with-right-part">
                            <span>{{$t("Ход_исполнения")}}</span>
                            <div class="twrp-content">
                                <div v-if="openAll" class="twrp-action" @click="toggleAll(false)">
                                    <v-icon role="button" small left>
                                        fas fa-chevron-up
                                    </v-icon>
                                </div>
                                <div v-else class="twrp-action" @click="toggleAll(true)">
                                    <v-icon role="button" small left>
                                        fas fa-chevron-down
                                    </v-icon>
                                </div>
                            </div>
                        </div>
                        <div class="resol-tree-wrapper">

                            <div class="resol-tree-header">
                                <div style="width: 50%;padding-left:18px;">{{ $t("Текст") }}</div>
                                <div style="width: 30%;">{{ $t("Исполнитель") }}</div>
                                <div style="width: 130px;flex-shrink: 0;">{{ $t("Контрольный_срок") }}</div>
                                <div style="width: 160px;flex-shrink: 0;">{{ $t("Статус") }}</div>
                                <div class="resol-tree-action-col"></div>
                            </div>

                            <ActItemsTree
                                :items="dataSource.Items"
                                :knownExecuters="knownExecuters"
                                :open-all="openAll"
                                :active-class="'resol-tree-active'"
                                :taskActions="taskActions"
                                @task-item-dbl-click="onTaskItemDblClick"
                                @execution-item-dbl-click="onExecutionItemDblClick"
                                @act-item-dbl-click="onActItemDblClick"
                            />

                        </div>

                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';
import ActItemsTree from '@/components/global/tabs/task-components/ActItemsTree.vue';

export default {
    name: "actitems-tab",
    components: {
        ActItemsTree
    },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            dataSource: null,
            knownExecuters: null,
            taskActions: null,
            openAll: true
        }
    },
    methods: {
        ...mapActions('documents/references', ['getWorkplaces']),
        ...mapActions(['setOverlayVisible']),
        async update() {
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            this.setOverlayVisible({ visible: true });
            let response = await httpAPI({
                url: `api/tabs/actitems/${this.$props.id}`,
                method: 'GET',
                headers: { 'isCommon': this.$store.getters['global/actionsource/isDataSourceCommon'] },
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (response)
            {
                response.data.Payload.Items.forEach(element => {
                    element.isOpen = true;
                });

                response.data.Payload.Items = this.$_(response.data.Payload.Items).orderBy(['controlDate'], ['asc']).value();

                this.dataSource = response.data.Payload;
                this.knownExecuters = await this.getWorkplaces();
            }
            this.setOverlayVisible({ visible: false });
        },
        toggleAll(val) {
            this.openAll = val;
        },
        async executeAction(btn) {
            this[btn.action](btn);
        },
        onTaskItemDblClick(item) {
            this.$eventBus.$emit('open-document', { id: item.id, type: 'Documents|Orders|CitizenStatements.ResolutionTask', common: item.IsCommon });
        },
        onExecutionItemDblClick(item) {
            this.$store.dispatch(`global/actionsource/ExecuterShowExecution`, { executionData : { resolutionId: item.resolutionId, executionId: item.executionId, common: item.IsCommon } });
        },
        onActItemDblClick(item) {
            this.$eventBus.$emit('open-document', { id: item.id, type: 'Chancellery|Documents.Document', common: item.IsCommon });
        },
    },
    async created() {
        await this.update();
    },
    mounted() {
        this.$eventBus.$on('update-active-tab', async () => {
            if (this.$parent.isActive)
                await this.update();
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('update-active-tab');
    }
}
</script>