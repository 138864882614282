<template>
    <div class="resol-tree">

        <div
            v-for="item in items"
            :key="item.id"
            class="div-table-rows-container"
        >

            <div 
                :class="[activeId === item.id  ? activeClass : '', item.isMain  ? 'font-weight-bold' : '', 'div-table-row']"
                @click.stop="actItemClick(item)"
                @dblclick.stop="actItemDblClick(item)"
            >

                <!-- Ячейка Текст-->
                <div class="div-table-row-cell" :style="{ 'width': `50%`, 'margin-left': `0px` }" >
                    
                    <div class="elipsis-text-wrap">

                        <!--Toggle-->
                        <span v-if="item.TaskItems.length > 0">
                            <i :class="[ item.isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right']" @click.stop="toggle(item)" @dblclick.stop="() => {}"></i>
                        </span>

                        <span v-if="!item.TaskItems.length > 0" class="resol-tree-no-icon-area"></span>

                        <v-common-chip v-if="item.isCommon === true" document-type="IncomingDocument"/>
                        <div class="etw-text-container" v-tooltip.top-center="item.text">
                            <span class="etw-text">
                                {{ $t("Пункт") }}{{ getTypeProtocol(item) }} № {{ item.Number }} {{ $t("от_время") }} {{ $moment(item.CreateDate).format("DD.MM.YYYY") }} &nbsp; - &nbsp; {{ item.Text }}                                
                            </span>
                        </div>

                    </div>

                </div>

                <!-- Ячейка Исполнитель-->
                <div class="div-table-row-cell" style="width: 30%;">

                </div>

                <!-- Ячейка Контрольный срок-->
                <div class="div-table-row-cell" style="width: 130px;flex-shrink: 0;">
                    <span v-if="item.ControlDate">
                        {{ $moment(item.ControlDate).format('DD.MM.YYYY') }}
                    </span>
                </div>
                
                <!-- Ячейка Статус-->
                <div class="div-table-row-cell" style="width: 160px;flex-shrink: 0;">
                    <StatusChip :status="avaibleStatuses[item.CalcStatus ? item.CalcStatus : $helpers.calculateDocumentStatus(item.ControlStatus, item.ControlDate, item.ExecutionDate, item.HasResolution)]"/>
                </div>

                <!-- Ячейка для действий (для строки пунктов нужна как заглушка выравнивания)-->
                <div class="resol-tree-action-col">
                </div>

            </div>

            <TaskItem
                v-show="item.isOpen"
                v-for="task in item.TaskItems"
                :key="task.id"
                :knownExecuters="knownExecuters"
                :item="task"
                :level="1"                
                :open-all="openAll"
                :active-id="activeId"
                :active-class="activeClass"
                :task-click="taskClickHandler"
                :task-dbl-click="taskDblClickHandler"
                :execution-click="executionClickHandler"
                :execution-dbl-click="executionDblClickHandler"
            />

        </div>

    </div>

</template>

<script>
import StatusChip from '@/components/global/chips/StatusChip'
import TaskItem from '@/components/global/tabs/task-components/TaskItem.vue'

export default {
    name: "ActItemsTree",
    components: {
        TaskItem,
        StatusChip
    },
    data() {
        return {
            activeId : null,
            activeItem: null,
        }
    },
    props: {
        items: Array,
        activeClass: String,
        knownExecuters: Object,
        openAll: Boolean,
    },
    computed: {
        avaibleStatuses() {
            return this.$store.getters['documents/references/GetAvaibleStatuses'];
        },
    },
    methods: {
        taskClickHandler(item) {
            if (this.activeId !== item.id) {
                this.activeId = item.id;
                this.activeItem = item;
                this.$emit('selected-change', item);
            }

            this.$emit('task-item-click', item);
        },
        taskDblClickHandler(item) {
            this.$emit('task-item-dbl-click', item);
        },
        executionClickHandler(item) {
            if (this.activeId !== item.ExecutionId) {
                this.activeId = item.ExecutionId;
                this.activeItem = item;
                this.$emit('selected-change', item);
            }
            
            this.$emit('execution-item-click', item);
        },
        executionDblClickHandler(item) {
            this.$emit('execution-item-dbl-click', item);
        },
        actItemClick(item) {
            if (this.activeId !== item.id) {
                this.activeId = item.id;
                this.activeItem = item;
                this.$emit('selected-change', item);
            }

            this.$emit('act-item-click', item);
        },
        actItemDblClick(item) {
            this.$emit('act-item-dbl-click', item);
        },
        toggle(item) {
            item.isOpen = !item.isOpen
        },
        getTypeProtocol(item){
            if (item.ClientType == "IAS")
                return "(ИАС)";
            else if (item.ClientType == "SED")
                return "(СЭД)";
            else 
                return "";
        }
    },
    watch: {
        openAll(newValue) {
            this.items.forEach(x => x.isOpen = newValue);
        }
    }
}
</script>